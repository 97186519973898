import Modal from 'react-bootstrap/Modal';
import ProductModal from '@/components/sections/ProductModal';

function ProductDescriptionModal({
  showProductModal,
  handleClose,
  productModalData,
}) {
  return (
    <>
      <Modal
        show={showProductModal}
        onHide={handleClose}
        className="view_product_model"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ProductModal product={productModalData} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProductDescriptionModal;
