import Image from 'next/image';
import { checkImgUrl } from '@/datalayer/config';
import { useState } from 'react';
import { useContext } from 'react';
import { ProductModalContext } from '@/context/ProductModalContext';
import Products from 'services/ProductsService';
import { replaceSlugIfNeeded } from 'utils/textHelpers';
import Spinner from 'react-bootstrap/Spinner';
import { useRouter } from 'next/router';

const RelatedProductsCard = ({ _product }) => {
  const router = useRouter();

  const [loading, setLoading] = useState(true);
  const [popLoading, setPopLoading] = useState(false);
  const { handleShow } = useContext(ProductModalContext);

  const handleShowProduct = async (hit) => {
    try {
      setPopLoading(true);
      const replacedSlug = replaceSlugIfNeeded(hit.slug);

      const data = await Products.getProduct(replacedSlug);

      const product = data[0];
      handleShow(product);
    } catch (e) {
      console.error(e);
    } finally {
      setPopLoading(false);
    }
  };

  return (
    <>
      <div className="relatedproduct-single" key={_product.id}>
        <a
          onClick={() => handleShowProduct(_product)}
          className="w-100 mx-auto inline-block"
        >
          <div
            className={
              loading
                ? 'relatedproduct-single-imgouter bp5-skeleton'
                : 'relatedproduct-single-imgouter'
            }
          >
            <Image
              onLoadingComplete={() => setLoading(false)}
              src={checkImgUrl(_product.thumbnail.id)}
              alt={_product.title}
              layout="fill"
              className="relatedproduct-single-img"
            />

            {popLoading && (
              <div className="loading-overlay">
                <Spinner animation="grow" role="status" variant="secondary" />
              </div>
            )}
          </div>
          <h4 className="smaller">{_product.title}</h4>
        </a>
      </div>
    </>
  );
};

export default RelatedProductsCard;
