import React, { createContext, useEffect, useState } from 'react';
import ProductDescriptionModal from '@/components/modals/ProductDescriptionModal';
import { useRouter } from 'next/router';
import { replaceSlugIfNeeded } from 'utils/textHelpers';
import Products from 'services/ProductsService';

export const ProductModalContext = createContext();

const removeProductQuery = (router) => {
  // Get the current URL and query parameters
  const { pathname, query } = router;

  // Create a new query object without the 'product' key
  const updatedQuery = { ...query };
  delete updatedQuery.product;

  // Update the URL with the new query parameters
  router.replace(
    {
      pathname,
      query: updatedQuery,
    },
    undefined,
    { shallow: true, scroll: false },
  );
};

export const ProductModalProvider = ({ children }) => {
  const router = useRouter();

  const [showProductModal, setShowProductModal] = useState(false);
  const [productModalData, setProductModalData] = useState([]);

  const handleGetProductDetails = async (hit, callback) => {
    try {
      const replacedSlug = replaceSlugIfNeeded(hit.slug);

      const data = await Products.getProduct(replacedSlug);

      const product = data[0];
      handleShow(product);
      callback();
    } catch (e) {
      console.error(e);
    }
  };

  const handleShow = async (product) => {
    if (!product) return;

    const newQuery = { ...router.query, ['product']: product.slug };

    router.replace(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      {
        scroll: false,
        shallow: true,
      },
    );

    setProductModalData(product);
    setShowProductModal(true);
  };

  const handleClose = () => {
    setShowProductModal(false);
    setProductModalData([]);
    removeProductQuery(router);
  };

  useEffect(() => {
    if (window)
      window.onpopstate = (e) => {
        if (showProductModal) {
          setShowProductModal(false);
        }
      };
  }, []);

  return (
    <ProductModalContext.Provider
      value={{ handleShow, handleClose, handleGetProductDetails }}
    >
      {children}
      {showProductModal && (
        <ProductDescriptionModal
          showProductModal={showProductModal}
          productModalData={productModalData}
          handleClose={handleClose}
        />
      )}
    </ProductModalContext.Provider>
  );
};
