import { useRouter } from 'next/router';
import Head from 'next/head';
import { useSiteInfo } from '@/context/SiteInfoContext';
import { checkImgUrl } from '@/datalayer/config';

const OpenGraphContainer = ({ title, description, image }) => {
  const router = useRouter();

  const { siteInfo } = useSiteInfo();

  const favicon = siteInfo.favicon.id;

  const defaultTitle = siteInfo.page_title;
  const defaultImage = checkImgUrl(siteInfo?.site_logo?.id ?? '') ?? '';

  return (
    <Head>
      {/* SEO Tags */}
      <title>{title ?? defaultTitle}</title>
      <meta
        name="description"
        content={
          description ?? 'Create Jewish Graphics - 1k+ templates, AI & Kosher'
        }
      />

      {/* Open Graph */}
      <meta property="og:title" content={title ?? defaultTitle} />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`${siteInfo.site_url}${router.asPath}`}
      />
      <meta property="og:image" content={image ?? defaultImage} />
      <meta
        property="og:description"
        content={
          description ?? 'Create Jewish Graphics - 1k+ templates, AI & Kosher'
        }
      />

      <link rel="canonical" href={`${siteInfo.site_url}${router.asPath}`} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={siteInfo.site_url} />
      <meta
        property="twitter:url"
        content={`${siteInfo.site_url}${router.asPath}`}
      />
      <meta name="twitter:title" content={title ?? defaultTitle} />
      <meta
        name="twitter:description"
        content={
          description ?? 'Create Jewish Graphics - 1k+ templates, AI & Kosher'
        }
      />
      <meta name="twitter:image" content={image ?? defaultImage} />

      {/* FavIcon */}
      <link rel="icon" href={checkImgUrl(favicon) + '.ico'} />
    </Head>
  );
};

export default OpenGraphContainer;
