import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { toast } from 'react-toastify';
import { checkImgUrl } from '../../datalayer/config';
import Link from 'next/link';
import kglogo from '@/images/icons/new-kg-logo.svg';
import { useActiveSubscription } from '../hooks/useActiveSubscription';
import { removeTags } from 'utils/textHelpers';
import RelatedProductsCard from '../card/RelatedProductsCard';
import { useSiteInfo } from '@/context/SiteInfoContext';
import { useRouter } from 'next/router';
import ShareGradientIcon from 'assets/jsx-icons/ShareGradientIcon';
import Products from 'services/ProductsService';
import Skeleton from 'react-loading-skeleton';
import {
  addDiscountedPrice,
  formatIntegerPrice,
} from 'utils/addDiscountedPrice';
import 'react-loading-skeleton/dist/skeleton.css';

export default function ProductModal({ product }) {
  const { activeSubscription } = useActiveSubscription();
  const [catProducts, setCatProducts] = useState([]);
  const [relatedProductsLoading, setRelatedProductsLoading] = useState(true);
  const { siteInfo } = useSiteInfo();
  const router = useRouter();
  const isMobile = router.query.mobile !== undefined;

  const discountedPrice = addDiscountedPrice(product.price, siteInfo);

  const username =
    !product.users ||
    !product.users.email ||
    product.users.email.includes('gorinsystems')
      ? 'Kosher Graphics Original'
      : (product.users?.first_name + ' ' ??
        '' + product.users?.last_name ??
        '');

  useEffect(() => {
    if (product.related_products && product.related_products.length > 0) {
      // If related_products is not empty, set it to catProducts
      setCatProducts(convertRelatedProducts(product.related_products));
      setRelatedProductsLoading(false);
    } else {
      // If related_products is empty, fetch additional products
      setRelatedProductsLoading(true);
      getRelatedCategoryProducts();
    }
  }, [product]);

  function convertRelatedProducts(relatedProductsData) {
    return relatedProductsData.map((item) => item.related_products_id);
  }

  async function getRelatedCategoryProducts() {
    try {
      let categorySlugs = product.category.find((cat) => {
        if (cat.Product_categories_cat_id.child?.length === 0) {
          // If it has no child, i.e. it is a child category
          return Boolean(cat.Product_categories_cat_id);
        }
      });

      if (!categorySlugs) {
        // If there are no child category
        categorySlugs = [product.category[0].Product_categories_cat_id.slug]; // select first parent category
      } else {
        categorySlugs = categorySlugs.Product_categories_cat_id.slug;
      }

      const categoryProducts = await Products.getRelatedProducts({
        categorySlugs,
        productSlug: product.slug,
        siteId: siteInfo.id,
        languageName: product.product_language.name,
        dateCreated: product.date_created,
      });

      setCatProducts(categoryProducts);
    } catch (e) {
      console.warn(e);
      setCatProducts(catProducts && catProducts.length ? [...catProducts] : []);
    } finally {
      setRelatedProductsLoading(false);
    }
  }

  const copyClipboard = () => {
    navigator.clipboard.writeText(location.href);
    toast.success('Link copied to clipboard.');
  };

  return (
    <div className="view_product_modal">
      <div className="row view_product_modal_top">
        <div className="col-6">
          <div className="view_product_modal_image d-flex align-items-center h-100 mx-auto">
            {product.thumbnail == null ? (
              ''
            ) : (
              <Image
                src={checkImgUrl(product.thumbnail.id)}
                alt={product.title}
                layout="fill"
                className="featuredproduct-single-img"
              />
            )}
          </div>
        </div>

        <div className="col-6">
          <div className="view_product_modal_details">
            <p className="product_title">{product.title}</p>

            {product.users ? (
              <div className="product-userinfo">
                {product.users.avatar != null &&
                username !== 'Kosher Graphics Original' ? (
                  <div className="product-userlogo">
                    <Image
                      src={checkImgUrl(product.users.avatar.filename_disk)}
                      alt=""
                      layout="fill"
                    />
                  </div>
                ) : (
                  <div className="product-userlogo kglogo">
                    <Image src={kglogo} alt="" layout="fill" />
                  </div>
                )}
                {product.users.designer_website != null ? (
                  <>
                    By{' '}
                    <Link href={product.users.designer_website}>
                      <a target="_blank">{username}</a>
                    </Link>
                  </>
                ) : (
                  <>By {username}</>
                )}
              </div>
            ) : (
              <div className="product-userinfo">
                <div className="product-userlogo kglogo">
                  <Image src={kglogo} alt="" layout="fill" />
                </div>
                By Kosher Graphics Original
              </div>
            )}

            <div className="product_customizebtns">
              <a
                className="button-contained customize-btn"
                rel="noreferrer"
                href={`/design/create/${product.slug}`}
                target={isMobile ? '_self' : '_blank'}
              >
                Customize this template
              </a>

              <div className="share-btn" onClick={() => copyClipboard()}>
                <ShareGradientIcon
                  primaryColor={siteInfo.primary_color}
                  secondaryColor={siteInfo.secondary_color}
                />
              </div>
            </div>
            <p className="other_text">{removeTags(product.description)}</p>
            {!activeSubscription && (
              <p className="product_price">
                {discountedPrice === 0 || discountedPrice === '0'
                  ? 'Free'
                  : `$${formatIntegerPrice(discountedPrice)}`}
              </p>
            )}
            <p className="other_text product_type">
              {product.product_type ? product.product_type.name : ''}
            </p>
          </div>
        </div>
      </div>
      {(relatedProductsLoading || catProducts.length > 0) && (
        <div className="row view_product_modal_bottom">
          <div className="col-12">
            <p className="related_products_heading">Related Graphics</p>
          </div>

          <div className="col-12 related_product_list">
            <div className="featuredproducts relatedproducts popup">
              {catProducts.length > 0
                ? catProducts.map((_product) => (
                    <React.Fragment key={_product.slug}>
                      {_product.thumbnail !== null && (
                        <RelatedProductsCard _product={_product} />
                      )}
                    </React.Fragment>
                  ))
                : relatedProductsLoading && (
                    <>
                      {Array(5)
                        .fill()
                        .map((item, index) => (
                          <div
                            className="featuredproduct-single relatedproduct-single product-skeleton"
                            key={index}
                          >
                            <div className="product-card">
                              <Skeleton height={`100%`} width={`100%`} />
                            </div>
                          </div>
                        ))}
                    </>
                  )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
