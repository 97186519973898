export const addDiscountedPrice = (price, siteInfo) => {
  if (!siteInfo.discount && !siteInfo.discount_value) return price;

  const priceNumber = Number(price);
  if (priceNumber === NaN) return price;

  if (siteInfo.discount) {
    const discountedPrice = (priceNumber * siteInfo.discount) / 100;
    return priceNumber - discountedPrice >= 0
      ? priceNumber - discountedPrice
      : 0;
  }

  if (siteInfo.discount_value) {
    if (priceNumber <= siteInfo.discount_value) return 0;
    return priceNumber - siteInfo.discount_value;
  }
};

export const formatIntegerPrice = (price) => {
  const num = Number(price);
  if (Number.isInteger(num)) return price;
  return Number(price).toFixed(2);
};
